<template>
  <div class="my-0 py-0 print-fab">
    <div
      v-if="!responsive"
      class="float-right d-flex flex-row toggle-crud"
      v-bind:class="{
        'active pl-2 ': open,
        'active no-crud': !visibility_crud,
      }"
    >
      <template>
        <slot></slot>
      </template>
      <!-- <div class="responsive-crud">
        <template v-if="open || !visibility_crud">
          <slot></slot>
        </template>
        <b-button
          size="sm"
          variant="none"
          v-if="visibility_crud"
          class="custom-button"
          @click="open = !open"
        >
          <b-icon-three-dots></b-icon-three-dots>
        </b-button>
      </div> -->
    </div>
    <div
      v-else
      class="float-right d-flex flex-row toggle-crud"
      v-bind:class="{
        'active pl-2 ': open,
        'active no-crud': !visibility_crud,
      }"
    >
      <template v-if="open || !visibility_crud">
        <slot></slot>
      </template>
      <b-button
        size="sm"
        variant="none"
        v-if="visibility_crud"
        class="custom-button"
        @click="open = !open"
      >
        <b-icon-three-dots></b-icon-three-dots>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "PrintFab",
  props: {
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters({
      visibility_crud: names.VISIBILITY_CRUD,
    }),
  },
};
</script>

<style scoped>
.toggle-crud {
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: var(--secondary-font-size) !important;
  letter-spacing: 0.3px;
  margin-right: 3em;
  transform: translateX(10%);
  /* opacity: 0.5; */
}
.toggle-crud:hover {
  opacity: 1;
}
.custom-button {
  /* background-color: var(--secondary-color); */
  /* background: #50a1ba; */
  color: white !important;
  margin-left: 0.2em;
  width: 26px;
  height: 26px;
  outline: 0;
}
.custom-button:hover {
  background: #62abc1;
  outline: 0;
}
.custom-button:focus {
  outline: 0;
}
.custom-button > .b-icon {
  transform: translateX(-20%);
}
.active {
  /* background-color: var(--secondary-color); */
  color: white;
}
.no-crud {
  padding-left: 0 !important;
}
.print-fab {
  z-index: 100;
  /* position: fixed; */
  right: 0;
}
.responsive-crud {
  display: none;
}
@media (max-width: 1280px) {
  .toggle-crud {
    margin-right: 1em;
  }
}
@media (max-width: 1080px) {
  .toggle-crud {
    margin-right: 0.1em;
    letter-spacing: normal;
    transform: translateX(2%);
  }
}
@media (max-width: 980px) {
  /* .responsive-crud {
    display: block;
  } */
  .print-fab {
    margin-right: 3em;
    margin-top: 0.45em !important;
    position: fixed;
    z-index: 0 !important;
  }
}
</style>